.header-container {
  width: 100%;
  position: sticky;
  top: 137px;
  left: 0;
  background-color: #D59847;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-links {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    flex: 1;

    a {
      display: block;
      cursor: pointer;
      text-align: center;
      color: white;
      text-decoration: none;
      padding: 14px;
      transition: color 0.3s, background-color 0.3s;

      &:hover {
        color: rgba(255, 255, 255, 0.8);
        background-color: rgba(255, 255, 255, 0.2);
      }

      &.active {
        color: rgba(255, 255, 255, 0.8);
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

@media screen and (max-width: 961px) {
  .header-container {
    display: none;
  }
}

.banner {
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  padding-top: 4rem;
  padding-bottom: 2rem;
  width: 100%;
  // height: 60vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .banner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start; 
    text-align: left; 
    padding: 40px;
    border-radius: 10px;
    margin-left: 50px;
  }

  .hero-title {
    font-size: 2rem;
    color: white;
    margin: 0 0 10px;
  }

  .hero-content {
    font-size: 2rem;
    color: white;
    margin: 0 0 20px;
  }

  .hero-button {
    font-size: 1rem;
    padding: 10px 20px;
    color: white;
    background-color: #D59847;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken(#D59847, 10%);
    }
  }
}

@media screen and (max-width: 961px) {
  .banner {
    // padding-top: 8rem;

    .banner-container {
      margin: 0;
      justify-content: center;
    }

    .hero-title {
      display: none;
    }

    .hero-content {
      font-size: 1rem;
      text-align: center;
      line-height: 1.5rem;
    }

    .banner-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
    }
  }
}

.profile-container {
  margin: 100px 50px 0 50px;
  padding-top: 30px;
  text-align: center;
}

.profile-title {
  font-size: 2.5rem;
  color: #D59847;
  margin-top: 3px;
}

.profile-scrollable-wrapper {
  // width: 100vw;
  // margin-left: calc(-50vw + 50%);
  background-color: #EDF7FE;
  // height: 50vh; 
  display: flex;
  align-items: center; 
  gap: 2rem;
  overflow: hidden;
}

.profile-scrollable {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 2rem;
  padding: 4rem 2rem;
  overflow: hidden;
  overflow-x: scroll;
}

.profile-card-container {
  background-color: #EFECEC;
  margin: 0 10px;

  .read-more-btn {
    background-color: #0D0D0D;
    font-size: 1.25rem;
    font-weight: 700;
    color: #FFFEFE;
    text-align: center;
    border-radius: 15px 15px 0 0;
    padding: 0.75rem 0;
    margin: 0;
    cursor: pointer;
    line-height: normal;
    
    &:hover {
      background-color: #333333;
    }
  }
}

.profile-card {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
}

.profile-image {
  width: 200px;
  height: 280px;
  object-fit: cover;
  margin-right: 20px;
}

.profile-info-container {
  width: 600px;
}

.profile-name {
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0;
  width: 100%;

  &.board-profile-name {
    font-size: 1.75rem;
  }
}

.profile-position {
  font-size: 1.25rem;
  color: #DA7656;
  font-weight: 600;
  margin: 0.75rem 0;
  width: 100%;

  &.board-profile-position {
    color: #CE6767;
    font-size: 1.25rem;
  }

  .board-sub-position {
    font-size: 1rem;
  }
}

.profile-info {
  font-size: 1rem;
  font-weight: 600;
  color: #444444;
  width: 100%;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 1rem;
}

@media screen and (max-width: 961px) {
  .profile-container {
    margin: 1.5rem auto 1rem auto;
    padding: 1rem 0;

    .profile-title {
      font-size: 1.5rem;
    }
  }

  .profile-scrollable-wrapper {

    .profile-scrollable {
      padding: 2rem 1rem;

      .profile-card-container {
        margin: 0;

        .profile-card {
          padding: 10px;
          width: 320px;

          .profile-image-container {

            .profile-image {
              width: 70px;
              height: 90px;
              object-fit: cover;
              margin-right: 10px;
            }
          }

          .profile-info-container {

            .profile-name {
              font-size: 0.75rem;

              &.board-profile-name {
                font-size: 0.75rem;
              }
            }

            .profile-position {
              font-size: 0.6rem;
              margin: 0.25rem 0;

              &.board-profile-position {
                color: #CE6767;
                font-size: 0.6rem;
              }

              .board-sub-position {
                font-size: 0.5rem;
              }
            }

            .profile-info {
              font-size: 0.5rem;
              margin: 0.3rem 0 0 0;
            }
          }
        }

        .read-more-btn {
          font-size: 1rem;
          padding: 0.25rem 0;
          border-radius: 10px 10px 0 0;
        }
      }
    }
  }
}
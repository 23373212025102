.board-member-container {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cirlce-bg {
        position: relative;

        &::before, &::after {
            content: "";
            position: absolute;
            border-radius: 500px;
            width: 400px;
            height: 375px;
            z-index: -1;
            background: transparent;
        }

        &::before {
            top: 10%;
            left: -6%;
            border: 2px solid;
            border-image: linear-gradient(#B87A27, #FFFFFF, #B87A27) 1;
        }

        &::after {
            top: 12%;
            left: -8%;
            border: 2px solid;
            border-image: linear-gradient(#D59847, #FFFFFF, #D59847) 1;
        }
    }

    .board-image-container {
        height: 450px;
        width: 350px;
        position: relative;
        overflow: hidden;

        .board-image {
            position: absolute;
            top: 0%;
            left: -50%;

            &.hm-mafo {
                left: -60%;
            }

            &.chakib-zhouagi {
                left: 0 !important;
            }

            &.desmopnd-ray {
                height: 1000px;
                top: -50% !important;
                left: -140% !important;
            }

            &.badir-al {
                height: 450px;
                left: -50% !important;
                top: 0 !important;
            }

            &.allen-wu {
                left: -50% !important;
            }

            &.lety-eposi {
                left: -30% !important;
            }
        }
    }

    .member-title {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: -0.75rem;
    }

    .designation {
        font-size: 1.5rem;
        font-weight: 700;
        color: #CE6767;
        text-align: center;
        margin-bottom: 3rem;
    }

    .heading {
        font-size: 1.875rem;
        font-weight: 700;
        color: #D59847;
    }

    .member-content {
        font-size: 1.25rem;
        font-weight: 400;
        margin: 0rem;
        line-height: 1.75rem;

        a {
            color: #509FD8;
            text-decoration: none;
        }
    }
}

.board-footer-banner {
    padding: 1rem;
    background-color: #D59847;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .banner-content {
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
        
        .banner-text {
            display: block;
            color: #FFFFFF;
            margin: 0 0 2rem 0;
            text-decoration: none;
        }
    }

    .download-profile {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem;
        cursor: pointer;

        .download-text {
            font-size: 1rem;
            font-weight: 600;
            color: #FFFFFF;
            margin: 0;
        }
    }
}

@media screen and (max-width: 961px) {
    .board-member-container {
        padding: 1rem;

        .cirlce-bg {
            position: relative;
    
            &::before, &::after {
                width: 180px;
                height: 180px;
            }
    
            &::before {
                top: 4%;
            }
    
            &::after {
                top: 6%;
            }
        }

        .board-image-container {
            width: 150px;
            height: 200px;

            .board-image {
                height: 200px;

                &.hm-mafo {
                    left: -40%;
                }

                &.chakib-zhouagi {
                    left: 0 !important;
                }

                &.desmopnd-ray {
                    height: 600px;
                    top: -90% !important;
                    left: -220% !important;
                }

                &.badir-al {
                    height: 200px !important;
                    left: -50% !important;
                    top: 0 !important;
                }

                &.allen-wu {
                    left: -50% !important;
                }

                &.lety-eposi {
                    left: -15% !important;
                }
            }
        }

        .member-title {
            text-align: center;
            font-size: 1.5rem;
        }

        .heading {
            font-size: 1.5rem;
        }
    
        .member-content {
            font-size: 1rem;
        }

        .designation {
            font-size: 1.25rem;
        }
    }

    .board-footer-banner {
        flex-direction: column;
        align-items: center;

        .qr-code {
            margin-bottom: 3rem;
        }
    }
}
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 4rem;
    position: relative;

    .thankyou-bg {
        position: absolute;
        top: 0;
        width: 350px;
        height: auto;
        z-index: -1;

        &.left {
            left: 0;
        }

        &.right {
            transform: rotate(180deg);
            right: 0;
        }
    }

    .not-found-img {
        width: 60%;

        &.thankyou {
            width: 150px;
            height: auto;
        }
    }

    .not-found-heading {
        font-size: 5rem;
        color: #D59847;
        margin: 2rem 0 1rem 0;

        &.thankyou {
            font-size: 3rem;
            text-align: center;
        }
    }

    .not-found-text {
        font-size: 1.25rem;
        color: #0D0C0C;
        font-weight: 600;
        margin: 0 0 3rem 0;
        text-align: center;

        &.thankyou {
            width: 50%;
        }
    }

    .go-to-home {

        .not-found-link {
            display: flex;
            align-items: center;
            text-decoration: none;
            gap: 1rem;

            .not-found-text {
                font-size: 2rem;
                color: #0D0C0C;
            }
        }

        .home-button {
            border: 1px solid #D59847;
            border-radius: 0.9rem;
            font-size: 1.75rem;
            font-weight: 700;
            padding: 0 1.25rem;
            box-shadow: 0 4px 4px #00000040;
            min-height: 4rem;

            &:hover {
                border-color: #D59847;
                color: #D59847;
            }
        }
    }
}

@media screen and (max-width: 961px) {
    .not-found-container {

        .thankyou-bg {
            width: 250px;
        }

        .not-found-img {
    
            &.thankyou {
                width: 100px;
                height: auto;
            }
        }

        .not-found-heading {
            font-size: 2.5rem;
            margin: 1.5rem 0 0.75rem 0;

            &.thankyou {
                font-size: 1.5rem;
            }
        }
    
        .not-found-text {
            font-size: 0.9rem;
            margin: 0 0 3rem 0;

            &.thankyou {
                width: 100%;
            }
        }
    
        .not-found-img {
            width: 90%;
        }

        .go-to-home {
    
            .home-button {
                border-radius: 0.5rem;
                font-size: 1.25rem;
                font-weight: 700;
                padding: 0 1.25rem;
                min-height: 3rem;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .not-found-container {

        .thankyou-bg {
            width: 200px;

            &.left {
                left: -15%;
            }

            &.right {
                right: -15%;
            }
        }

        .not-found-img {
    
            &.thankyou {
                width: 75px;
                height: auto;
            }
        }
    }
}
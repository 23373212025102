.aimsgoals-container {
  display: flex;
  justify-content: space-between;
  margin: 100px 50px 0 50px;
}

.aimsgoals-logo {
  max-width: 40%;
  padding-left: 50px; 
}

.aimsgoals-title {
  font-size: 2.5rem;
  color: #D59847;
  margin-top: 3px;
}

.aimsgoals-content {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.aimsgoals-content p {
  margin: 0 0 10px;
  font-size: 1.5rem;    
}

.aimsgoals-content ul {
  margin: 0;
  padding-left: 20px;
  list-style-type: disc;
}

.aimsgoals-content ul li {
  margin-bottom: 5px;
  font-size: 1.2rem;    
}

.goals {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-top: 50px;
}

.goals p {
  margin: 0 0 10px;
  margin-left: 40px;
  font-size: 1.5rem;
}

.goals-content {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
}

// .goal-item {
//   background-color: gray;
//   color: white;
//   text-align: center;
//   padding: 10px;
//   cursor: pointer;
//   border-radius: 5px;
//   min-width: 30px;
//   max-width: 1000px;
//   margin: 20px;
// }

.goals-logo {
  max-width: 40%;
  padding-left: 40px;
}

.aimsgoals-button {
  display: block;
  width: max-content;
  font-size: 1.5rem;
  padding: 0.75rem 1.25rem;
  color: white;
  background-color: #D59847;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center;
  margin-top: 4rem;
}

.aimsgoals-button:hover {
  background-color: darken(#D59847, 10%);
}

.tables-container{
  margin-left: 25px;
}
.aimsgoals-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px; 
  margin-top: 20px; 
}

.aimsgoals-item {
  background-color: #e0e0e0; 
  padding: 0.75rem 1.25rem;
  text-align: center;
  color: black;
  border-radius: 0.3rem;
  font-weight: 700;
  display: grid;
  place-items: center;
}

@media screen and (max-width: 961px) {
  .aimsgoals-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin: 1.5rem;

    &.goals {
      flex-direction: column;

      .aimsgoals-title {
        font-size: 1.5rem;
        margin: 1rem 0;
      }

      p {
        font-size: 1rem;
        line-height: 1.5rem;
        width: 100%;
        margin: 0 0 1rem;
      }

      .goals-content {
        flex-direction: column;

        .goals-logo {
          padding: 0;
          max-width: 100%;
        }

        .tables-container {
          margin: 0;

          .aimsgoals-grid {
            grid-template-columns: repeat(2, 1fr);

            .aimsgoals-item {
              font-size: 1rem;
              padding: 0.5rem;
            }
          }
        }
      }
    }

    .aimsgoals-logo {
      max-width: 100%;
      margin-top: 1.5rem;
      height: auto;
      padding: 0;
    }

    .aimsgoals-content {
      max-width: 100%;

      .aimsgoals-title {
        font-size: 1.5rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.5rem;
        width: 100%;
        margin: 0 0 1rem;
        padding-top: 0 !important;
      }

      ul {
        margin-top: 1rem;
        
        li {
          font-size: 1rem;
        }
      }
    }
  }

  .aimsgoals-button {
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }
}
.about-us-image-container {
    max-height: 700px;
    overflow: hidden;
    position: relative;

    .about-us-image {
        width: 100%;
    }

    .about-drop-down {
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 2;
        border-radius: 6px;
        border: 1px solid #AFAFAF;
        width: 200px;

        &:focus {
            border: 1px solid #AFAFAF;
            box-shadow: none;
        }

        &:hover {
            border-color: #AFAFAF;
        }

        .ant-select-selection-item {
            color: #BFBFBF;
            font-size: 0.75rem;
        }
    }
}

.about-us-container {
    padding: 4rem;

    .about-us-heading {
        font-size: 2.75rem;
        font-weight: 700;
        color: #D59847;
        margin: 0 0 1.5rem 0;
    }

    .about-us-text {
        font-size: 1.5rem;
        font-weight: 400;
        color: #0C0B0B;
        line-height: 2rem;

        &.footer-text {
            width: 100%;
        }
    }

    .about-us-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        margin: 5rem 0;
    }
    @media screen and (max-width: 1024px) {
        .about-us-section {
            flex-direction: column;
            align-items: center;
            margin: 5rem 0;
        }
    }

    .learn-more-btn {
        background-color: #D59847;
        border-radius: 10px;
        font-size: 1.5rem;
        font-weight: 600;
        color: #FFFFFF;
        border: none;
        padding: 0.5rem 6rem;
        margin-top: 2rem;
        cursor: pointer;
        &:hover {
            background-color: darken(#D59847, 10%);
        }
    }
}

.footer-img-container {
    position: relative;
    height: 500px;
    overflow: hidden;
    margin: 2rem 0;

    .footer-image {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

@media screen and (max-width: 961px) {
    .about-us-image-container {
        max-height: 250px;

        .about-us-image {
            width: 100%;
        }
    }

    .about-us-container {
        padding: 1rem;

        .about-us-heading {
            font-size: 1.35rem;
            margin: 1.5rem 0;
        }

        .about-us-text {
            font-size: 1rem;
            line-height: 1.75rem;

            &.footer-text {
                width: 100%;
            }
        }
        
        .about-us-section {
            flex-direction: column;
            margin: 2rem 0;
            align-items: center;
    
            .about-us-sec-img {
                max-width: 90%;
            }
        }

        .learn-more-btn {
            font-size: 1rem;
            padding: 0.5rem 3rem;
        }
    }
    
    .footer-img-container {
        position: relative;
        height: 250px;
        overflow: hidden;
        margin: 0;

        .footer-image {
            width: 100%;
            height: 250px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

}